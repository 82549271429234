import React from "react";
import Layout from "../components/layout";

export default ({ pageContext }) => {
    const { node } = pageContext;

    return (
        <Layout>
            {node.description && (
                <div
                    className="container padding-left-24 padding-right-24 padding-top-64-widescreen padding-bottom-64-widescreen content"
                    dangerouslySetInnerHTML={{
                        __html: node.description.childMarkdownRemark.html
                    }}
                />
            )}
            {node.image && (
                <div
                    className="background-image padding-8-mobile"
                    style={{
                        backgroundImage: `url(${node.image.file.url})`
                    }}
                >
                    {node.imageText && <h2>{node.imageText}</h2>}
                </div>
            )}
            {node.content && (
                <div className="container padding-left-24 padding-right-24 padding-top-64-widescreen padding-bottom-64-widescreen">
                    <div
                        className="content"
                        dangerouslySetInnerHTML={{
                            __html: node.content.childMarkdownRemark.html
                        }}
                    />
                </div>
            )}
        </Layout>
    );
};
